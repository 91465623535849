<template>
  <div>
    <b-card bg-variant="light"
            header="Technical evaluation"
            header-bg-variant="dark"
            header-text-variant="white">
      <b-tabs no-body>
        <b-tab active
               class="ma-1 mt-4"
               title="Technical evaluation">
          <b-row align-h="between"
                 no-gutters>
            <call-filter :id="id"
                         ref="call_filter"
                         v-model="proposals"
                         class="mb-2"
                         @load_data="loadDataFor"
                         @selected_call_change="call_change"
                         @update_proposals="refresh_proposals_filter"/>
            <button-spinner :loading="zipLoading"
                            class="mb-2"
                            variant="info"
                            @click="downloadZipWrapper"/>
          </b-row>
          <!-- refresh is mixins method use to update all components -->

          <b-table :current-page="currentPage"
                   :fields="fields"
                   :items="proposals"
                   :per-page="perPage"
                   class="table-responsive"
                   head-variant="light"
                   hover
                   striped
                   thead-class="text-center"
                   @row-clicked="setShowFinalSubmit">
            <template v-slot:top-row="{fields}">
              <proposal-filter :id="id"
                               v-model="proposals"
                               :fields="fields"
                               :not_include_key="['actions', 'ordinalNumber']"
                               :selected_call="selectedCall"/>
            </template>

            <template v-slot:cell(ordinalNumber)="row">
              <base-column-ordinal-number v-bind="{index: row.index, perPage, currentPage}"/>
            </template>

            <template v-slot:cell(experiment_color.evaluated_color)="row">
              <strong :class="'text-' + getExperimentColor(row.item.experiment_color.evaluated_color)">
                {{ row.item.experiment_color.evaluated_color.toUpperCase() }}
              </strong>
            </template>

            <template v-slot:cell(experiment_color.final_evaluated_color)="row">
              <strong :class="'text-' + getExperimentColor(row.item.experiment_color.final_evaluated_color)">
                {{ row.item.experiment_color.final_evaluated_color.toUpperCase() }}
              </strong>
            </template>

            <template v-slot:cell(actions)="row">
              <div @click="setShowFinalSubmit(row.item, undefined, $event)"
                   @keyup.down="setShowFinalSubmit(row.item, undefined, $event)">
                <action-download-pdf :proposal="row.item"/>

                <template v-if="isSynchrotron">
                  <action-download-additional-sample-declaration-pdf v-if="isAdditionalSampleDeclaration(row.item)"
                                                                     :proposal="(row.item)"/>
                </template>

                <base-icon-button-comment v-b-modal.technical_modal
                                          :disabled="!isUserEditor"
                                          :title="editable(row.item) ? 'Edit evaluation' : 'View evaluation'"
                                          @click="setProposal(row.item)"/>

                <base-double-click-submit-button v-if="editable(row.item) && isUserEditor"
                                                 :btn-options="submitBtnOptions"
                                                 :is-confirmation="isFinalSubmit(row.item)"
                                                 class="d-block ml-auto"
                                                 @click="submitEvaluation(row.item)"/>
              </div>
            </template>
          </b-table>
          <b-table-footer v-model="loading"
                          :currentPage="currentPage"
                          :data_length="proposals.length"
                          :perPage="perPage"
                          @change_page="changePage"/>
        </b-tab>
      </b-tabs>
    </b-card>
    <b-modal id="technical_modal"
             :ref="modal_ref"
             bg-variant="light"
             header-bg-variant="dark"
             header-text-variant="white"
             hide-footer
             lazy
             size="lg"
             title="Technical evaluation"
             @hidden="closeModal">
      <technical-modal v-model="proposal"
                       :fields="modalFields"
                       :isEditable="editable(proposal)"
                       @closeModal="close_modal_slot"/>
    </b-modal>
  </div>
</template>

<script>
  import ActionDownloadPdf from '@/components/actions/ActionDownloadPdf';
  import technicalModal from '@/components/evaluation/technical/technical_modal';
  import options from '@/json/feasibility';
  import submitBtnOptions from '@/json/doubleClickSubmitButton';
  import callFilter from '@/components/call_filter';
  import proposalFilter from '@/components/proposal_filter';
  import bTableFooter from '@/components/b_table_footer';
  import { envMixin } from '@/mixins/environment';
  import ButtonSpinner from '@/components/buttonSpinner';
  import BaseColumnOrdinalNumber from '@/components/baseComponents/BaseColumnOrdinalNumber';
  import BaseDoubleClickSubmitButton from '@/components/baseComponents/BaseDoubleClickSubmitButton';
  import BaseIconButtonComment from '@/components/baseComponents/baseIcons/BaseIconButtonComment';
  import trafficLightsOptions from '@/json/trafficLightsAcceptanceMarkSafety';
  import ActionDownloadAdditionalSampleDeclarationPdf
    from '@/components/actions/ActionDownloadAdditionalSampleDeclarationPdf';

  export default {
    components: {
      ActionDownloadAdditionalSampleDeclarationPdf,
      ActionDownloadPdf,
      BaseColumnOrdinalNumber,
      BaseDoubleClickSubmitButton,
      BaseIconButtonComment,
      ButtonSpinner,
      technicalModal,
      callFilter,
      proposalFilter,
      bTableFooter,
    },
    mixins: [envMixin],
    props: {
      fields: {
        type: Array,
        default: () => [],
      },
      modalFields: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        // necessary for proposal and calls filter feature
        proposals: [],
        id: 1,
        selectedCall: '',
        // end

        // necessary for b-table feature
        currentPage: 1,
        perPage: 20,
        // end

        // use to hide modal
        // eslint-disable-next-line camelcase
        modal_ref: 'modalRef',

        loading: false,
        zipLoading: false,
        proposal: {},
        index: 0,
        options: options.options,
        trafficLightsOptions,
        submitBtnOptions,
        finalSubmit: '',
      };
    },
    computed: {
      isSynchrotron() {
        return this.environment === 'synchrotron';
      },
      isUserEditor() {
        const permissions = this.$store.getters['login/getLoggedUserAttribute']('permissions') || [];
        if (this.environment === 'synchrotron') {
          return permissions.includes('EDIT_TEF_EVAL');
        }
        return permissions.includes('EDIT_CRYO_TEF_EVAL');
      },
    },
    methods: {
      changePage(val) {
        this.currentPage = val;
      },
      closeModal() {
        this.updateProposal();
        this.proposal = {};
      },
      async downloadZipWrapper() {
        this.zipLoading = true;
        const params = [];
        params.push({ call: this.$refs.call_filter.selected_call });
        if (this.environment === 'synchrotron') {
          params.push({ endstation: true });
        }
        await this.download_zip(...params);
        this.zipLoading = false;
      },
      editable(proposal) {
        if (proposal?.technical_evaluation?.states) {
          return proposal.technical_evaluation.states.name !== 'submitted';
        }
        return true;
      },
      getExperimentColor(status) {
        let variant = 'secondary';
        this.trafficLightsOptions.forEach((option) => {
          if (option.value === status) {
            variant = option.disabledVariant;
          }
        });
        return variant;
      },
      isAdditionalSampleDeclaration(item) {
        return item.additional_sample_declaration?.states?.name === 'submitted';
      },
      isFeasibilitySet(proposal) {
        const { feasibility } = proposal.technical_evaluation;
        return !(feasibility === null || typeof feasibility === 'undefined');
      },
      async loadDataFor(call) {
        const callParam = { call };
        this.loading = true;
        try {
          const response = await this.axios.get(
            `/documents/${this.environment}/technical`,
            { params: callParam },
          );
          if (response.status !== 204) {
            this.proposals = response.data.documents;
            const { authors } = response.data;
            this.proposals = this.proposals.map((proposal) => {
              const { experiment_color = {} } = proposal;
              // eslint-disable-next-line camelcase
              experiment_color.evaluated_color = experiment_color.evaluated_color || '';
              // eslint-disable-next-line camelcase
              experiment_color.final_evaluated_color = experiment_color.final_evaluated_color || '';
              return {
                ...proposal,
                // eslint-disable-next-line camelcase
                technical_evaluation: proposal.technical_evaluation || {},
                // eslint-disable-next-line camelcase
                experiment_color,
                author: `${authors[proposal._id.$oid].name}\n${authors[proposal._id.$oid].email}`,
              };
            });
          }
          // update data in proposal-filter, call-filter component
          this.$emit('setup_proposal', this.proposals);
          this.loading = false;
        } catch {
          this.$notify({ type: 'error', title: 'Communication error' });
        }
      },
      setProposal(proposal) {
        this.proposal = proposal;
      },
      statusMessage(param) {
        this.$notify({ type: param.status || 'error', title: param.message || 'Feasibility not set' });
      },
      isFinalSubmit(proposal) {
        return proposal._id.$oid === this.finalSubmit;
      },
      setShowFinalSubmit(proposal, unusedIndex, event) {
        this.finalSubmit = '';
        if (event.target.id === this.submitBtnOptions.attempt.icon.id) {
          this.finalSubmit = proposal._id.$oid;
        }
      },
      submitEvaluation(proposal) {
        this.setProposal(proposal);

        if (this.isFeasibilitySet(this.proposal)) {
          const url = `/documents/${this.environment}/${this.proposal._id.$oid}/technical`;
          this.axios.patch(url)
            .then((response) => {
              if (response.data.error) {
                this.statusMessage({ message: response.data.error });
              } else {
                this.proposal.technical_evaluation.states.name = 'submitted';
                this.statusMessage({ status: 'success', message: response.data.msg });
              }
            })
            .catch((error) => {
              this.statusMessage({ message: error.response.data.error });
            });
        } else {
          this.statusMessage({});
        }
      },
      updateProposal() {
        for (let i = 0; i < this.proposals.length; i += 1) {
          if (this.proposals[i]._id.$oid === this.proposal._id.$oid) {
            this.$set(this.proposals, i, this.proposal);
          }
        }
      },
    },
  };
</script>

<style lang="scss"
       scoped>
  .card {
    margin: 2rem;
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    text-align: center !important;
  }

  ::-moz-placeholder { /* Firefox 19+ */
    text-align: center !important;
  }

  :-ms-input-placeholder { /* IE 10+ */
    text-align: center !important;
  }

  :-moz-placeholder { /* Firefox 18- */
    text-align: center !important;
  }
</style>
