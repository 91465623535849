import { rapidAccessEvaluation as urlLayer } from '@/api/url_layer';
import Vue from 'vue';

export const rapidAccessEvaluation = {
  namespaced: true,
  state: {
    finalEvaluationProposals: [],
    finalEvaluationSelectedProposal: {},
  },
  mutations: {
    setFinalEvaluationProposals(state, proposals) {
      state.finalEvaluationProposals = proposals;
    },
    setFinalEvaluationProposal(state, proposal) {
      const index = state.finalEvaluationProposals.findIndex((el) => el._id.$oid === proposal._id.$oid);
      Vue.set(state.finalEvaluationProposals, index, proposal);
    },
    setFinalEvaluationSelectedProposal(state, selectedProposal) {
      state.finalEvaluationSelectedProposal = selectedProposal;
    },
  },
  actions: {
    notifyHandler({}, notification) {
      Vue.notify(notification);
    },
    async loadFinalEvaluationProposals({ commit, dispatch }, environment) {
      let responseMessage;
      try {
        const response = await urlLayer.loadRapidAccessFinalEvaluationProposals(environment);
        commit('setFinalEvaluationProposals', response.data.proposals);
        responseMessage = { title: 'Proposals loaded successfully', type: 'success' };
      } catch (error) {
        commit('setFinalEvaluationProposals', []);
        responseMessage = { title: error.response.data, type: 'error' };
      }
      dispatch('notifyHandler', responseMessage);
    },
    async saveSelectedProposalFinalEvaluation({ commit, getters, dispatch }, evaluation) {
      let responseMessage;
      try {
        const proposalId = getters.finalEvaluationSelectedProposalId;
        const response = await urlLayer.saveRapidAccessProposalFinalEvaluation(proposalId, evaluation);
        commit('setFinalEvaluationSelectedProposal', response.data.proposal);
        commit('setFinalEvaluationProposal', response.data.proposal);
        responseMessage = { title: 'Proposal evaluation saved successfully', type: 'success' };
      } catch (error) {
        const errorMessage = error.response.data.error ? error.response.data.error : error.response.data;
        responseMessage = {
          type: 'error',
          title: error.response.data === false ? 'Saving error!' : errorMessage,
        };
      }
      dispatch('notifyHandler', responseMessage);
    },
    async submitSelectedProposalFinalEvaluation({ commit, getters, dispatch }) {
      let responseMessage;
      try {
        const proposalId = getters.finalEvaluationSelectedProposalId;
        const response = await urlLayer.submitRapidAccessProposalFinalEvaluation(proposalId);
        commit('setFinalEvaluationSelectedProposal', response.data.proposal);
        commit('setFinalEvaluationProposal', response.data.proposal);
        responseMessage = { title: 'Proposal evaluation submitted successfully', type: 'success' };
      } catch (error) {
        const errorMessage = error.response.data.error ? error.response.data.error : error.response.data;
        responseMessage = {
          type: 'error',
          title: error.response.data === false ? 'Saving error!' : errorMessage,
        };
      }
      dispatch('notifyHandler', responseMessage);
    },
  },
  getters: {
    finalEvaluationSelectedProposalId: (state) => state.finalEvaluationSelectedProposal._id.$oid || '',
    finalEvaluationSelectedProposalInhouseGrade: (state) => state.finalEvaluationSelectedProposal.inhouse_grade || {},
  },
};
