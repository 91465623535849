import Vue from 'vue';
import Router from 'vue-router';
import { store } from '@/store';
import { hasProperty } from '@/helpers';

Vue.use(Router);

export const router = new Router({
  routes: [
    {
      path: '/',
      redirect: '/home',
    },
    {
      path: '/cryo/mngr/calendar',
      name: 'Manage calendar',
      component: () => import('@/components/operational/cryoem/cryoem_reservations_dashboard'),
      meta: { permission: ['READ_MNGR_CRYO_CAL'] },
    },
    {
      path: '/cryo/calendar',
      name: 'CryoEM calendar',
      component: () => import(
        '@/components/calendars/cryoem/CryoemCalendarUserDashboard'
      ),
      meta: { permission: ['LOGIN'] },
    },
    {
      path: '/synchrotron-calendar-manager',
      name: 'Manager synchrotron calendar',
      component: () => import(
        '@/components/proposal/synchrotron/calendar/machine_calendar_dashboard'
      ),
      meta: { permission: ['READ_OP_BEAM_CAL'] },
    },
    {
      path: '/users/me',
      name: 'User profile & affiliations',
      component: () => import('@/components/userProfile/UserProfile'),
      meta: { permission: ['LOGIN'] },
    },
    {
      path: '/user/:id/token/:token',
      name: 'Activate account',
      component: () => import('@/components/before_login/activate_account'),
    },
    {
      path: '/user/regulations',
      name: 'Regulations',
      component: () => import('@/components/clause/clause_wrapper'),
    },
    {
      path: '/home/accessibility',
      name: 'Accessibility statement wrapper',
      component: () => import('@/components/clause/AccessibilityStatementWrapper'),
    },
    {
      path: '/affiliation/:id/token/:token',
      name: 'Affiliations activate',
      component: () => import('@/components/before_login/affiliation_activation'),
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/components/home/HomeDashboard'),
      meta: { permission: ['MENU_DASHB'] },
    },
    {
      path: '/proposals',
      name: 'Synchrotron proposals',
      component: () => import('@/components/synchrotron_wrapper'),
      meta: { permission: ['MENU_PROPS_PROPS'] },
    },
    {
      path: '/cryo_proposals',
      name: 'CryoEm proposals',
      component: () => import('@/components/cryoem_wrapper'),
      meta: { permission: ['MENU_PROPS_PROPS'] },
    },
    {
      path: '/proposals/:id',
      name: 'proposals',
      component: () => import('@/components/SynchrotronProposalForm'),
      meta: { permission: ['MENU_PROPS_PROPS'] },
    },
    {
      path: '/proposals/cryoem/:id',
      name: 'cryoem_proposals',
      component: () => import('@/components/CryoemProposalForm'),
      meta: { permission: ['MENU_PROPS_PROPS'] },
    },
    {
      path: '/proposals/history/:id/:environment',
      name: 'ProposalFullDetailsDashboard',
      component: () => import('@/components/ProposalFullDetailsDashboard'),
      meta: { permission: ['READ_FG_EVAL'] },
      props: true,
    },
    {
      path: '/feedbacks',
      name: 'Feedbacks',
      component: () => import('@/components/proposal/feedbacks/feedback_dashboard'),
      meta: { permission: ['MENU_PROPS_FEEDS'] },
    },
    {
      path: '/feedbacks/:doc_id/:user_id',
      name: 'feedback',
      component: () => import('@/components/proposal/feedbacks/feedback_form'),
      meta: { permission: ['MENU_PROPS_FEEDS'] },
    },
    {
      path: '/teams',
      name: 'team forms',
      component: () => import('@/components/proposal/team/team_dashboard'),
      meta: { permission: ['MENU_PROPS_TEAMS'] },
    },
    {
      path: '/experiment_reports',
      name: 'experiment reports',
      component: () => import('@/components/proposal/experiment_report/experiment_dashboard'),
      meta: { permission: ['MENU_PROPS_REPS'] },
    },
    {
      path: '/documents/:id/experiment_reports',
      name: 'experiment report',
      component: () => import('@/components/proposal/experiment_report/experiment_form'),
      meta: { permission: ['MENU_PROPS_REPS'] },
    },
    {
      path: '/proposals/:id/synchrotron/team',
      name: 'Cryoem team form',
      component: () => import('@/components/proposal/synchrotron/team_form_wrapper'),
      meta: { permission: ['MENU_PROPS_TEAMS'] },
    },
    {
      path: '/proposals/:id/cryoem/team',
      name: 'Synchrotron team form',
      component: () => import('@/components/proposal/cryo_em/team_form_wrapper'),
      meta: { permission: ['MENU_PROPS_TEAMS'] },
    },
    {
      path: '/trainings',
      name: 'trainings',
      component: () => import('@/components/activities/trainings'),
      meta: { permission: ['MENU_PROPS_TRNG'] },
    },
    {
      path: '/trainings/:id',
      name: 'training',
      component: () => import('@/components/activities/training'),
      meta: { permission: ['MENU_PROPS_TRNG'] },
    },
    {
      path: '/additional-sample-declarations',
      name: 'Additional sample declarations',
      component: () => import(
        '@/components/proposal/additionalSampleDeclaration/AdditionalSampleDeclarationDashboard'
      ),
      meta: { permission: ['MENU_PROPS_ASD'] },
    },
    {
      path: '/documents/:id/additional-sample-declarations',
      name: 'Additional sample declaration',
      component: () => import(
        '@/components/proposal/additionalSampleDeclaration/AdditionalSampleDeclarationForm'
      ),
      meta: { permission: ['MENU_PROPS_ASD'] },
    },
    {
      path: '/evaluation/technical-eval/synchrotron',
      name: 'Synchrotron technical evaluation',
      component: () => import(
        '@/components/evaluation/synchrotron/technical_evaluation/synchrotron_technical_evaluation_dashboard'
      ),
      meta: { permission: ['MENU_EV_TEF'] },
    },
    {
      path: '/evaluation/technical-eval/cryoem',
      name: 'Cryoem technical evaluation',
      component: () => import(
        '@/components/evaluation/cryoem/technical_evaluation/cryoem_technical_evaluation_dashboard'
      ),
      meta: { permission: ['MENU_EV_CRYO_TEF'] },
    },
    {
      path: '/evaluation/final-grades/synchrotron',
      name: 'Synchrotron final grade',
      component: () => import(
        '@/components/evaluation/final_grade/final_grade_dashboard'
      ),
      meta: { permission: ['MENU_EV_FG'] },
    },
    {
      path: '/evaluation/final-grades/cryoem',
      name: 'Cryoem final grade',
      component: () => import(
        '@/components/evaluation/final_grade/final_grade_dashboard'
      ),
      meta: { permission: ['MENU_EV_CRYO_FG'] },
    },
    {
      path: '/evaluation/reviews/synchrotron',
      name: 'Synchrotron Reviews',
      component: () => import('@/components/evaluation/synchrotron/review/SynchrotronReviewDashboard'),
      meta: { permission: ['MENU_EV_REV'] },
    },
    {
      path: '/evaluation/reviews/cryoem',
      name: 'Cryoem Reviews',
      component: () => import('@/components/evaluation/cryoem/review/CryoemReviewDashboard'),
      meta: { permission: ['MENU_EV_CRYO_REV'] },
    },
    {
      path: '/evaluation/safety-eval/synchrotron',
      name: 'Synchrotron safety evaluation',
      component: () => import(
        '@/components/evaluation/safety/SafetyEvaluationDashboard'
      ),
      meta: { permission: ['MENU_EV_SEF'] },
    },
    {
      path: '/evaluation/safety-eval/cryoem',
      name: 'Cryoem safety evaluation',
      component: () => import(
        '@/components/evaluation/safety/SafetyEvaluationDashboard'
      ),
      meta: { permission: ['MENU_EV_CRYO_SEF'] },
    },
    {
      path: '/evaluation/radiation-eval/synchrotron',
      name: 'Synchrotron radiation evaluation',
      component: () => import(
        '@/components/evaluation/radiation/RadiationEvaluationDashboard'
      ),
      meta: { permission: ['MENU_EV_REF'] },
    },
    {
      path: '/evaluation/radiation-eval/cryoem',
      name: 'Cryoem radiation evaluation',
      component: () => import(
        '@/components/evaluation/radiation/RadiationEvaluationDashboard'
      ),
      meta: { permission: ['MENU_EV_CRYO_REF'] },
    },
    {
      path: '/evaluation/feedbacks-reports',
      name: 'Feedbacks & reports',
      component: () => import('@/components/evaluation/feedbacks_reports/feedbacks_reports'),
      meta: { permission: ['MENU_EV_FEEDREP'] },
    },
    {
      path: '/evaluation/ceric/synchrotron',
      name: 'Synchrotron CERIC',
      component: () => import('@/components/proposal/ceric/synchrotron_ceric_manage_dashboard'),
      meta: { permission: ['READ_PROP_CERIC'] },
    },
    {
      path: '/evaluation/ceric/cryoem',
      name: 'Cryoem CERIC',
      component: () => import('@/components/proposal/ceric/cryoem_ceric_manage_dashboard'),
      meta: { permission: ['READ_PROP_CERIC'] },
    },
    {
      path: '/evaluation/rapid-access/synchrotron/technical',
      name: 'Synchrotron rapid access technical evaluation',
      component: () => import('@/components/evaluation/rapidAccess/technical/RapidAccessEvaluationDashboard'),
      meta: { permission: ['MENU_EVALUATION_RAPID_ACCESS_TECHNICAL'] },
    },
    {
      path: '/evaluation/rapid-access/synchrotron/safety',
      name: 'Synchrotron rapid access safety evaluation',
      component: () => import(
        '@/components/evaluation/rapidAccess/safety/RapidAccessSafetyEvaluationDashboard'
      ),
      meta: { permission: ['MENU_EVALUATION_RAPID_ACCESS_SAFETY'] },
    },
    {
      path: '/evaluation/rapid-access/synchrotron/radiation',
      name: 'Synchrotron rapid access radiation evaluation',
      component: () => import(
        '@/components/evaluation/rapidAccess/radiation/RapidAccessRadiationEvaluationDashboard'
      ),
      meta: { permission: ['MENU_EVALUATION_RAPID_ACCESS_RADIATION'] },
    },
    {
      path: '/evaluation/management/synchrotron',
      name: 'Synchrotron management evaluation',
      component: () => import('@/components/evaluation/management/ManagementEvaluationDashboard'),
      meta: { permission: ['MENU_EVALUATION_MANAGEMENT'] },
    },
    {
      path: '/evaluation/management/cryoem',
      name: 'Cryoem management evaluation',
      component: () => import('@/components/evaluation/management/ManagementEvaluationDashboard'),
      meta: { permission: ['MENU_CRYO_EVALUATION_MANAGEMENT'] },
    },
    {
      path: '/operational/users',
      name: 'User',
      component: () => import('@/components/operational/users/users_dashboard'),
      meta: { permission: ['MENU_OP_USERS'] },
    },
    {
      path: '/operational/roles',
      name: 'Roles',
      component: () => import('@/components/operational/role_dashboard'),
      meta: { permission: ['MENU_OP_ROLES'] },
    },
    {
      path: '/operational/permissions',
      name: 'Permissions',
      component: () => import('@/components/operational/permission_dashboard'),
      meta: { permission: ['MENU_OP_PERMS'] },
    },
    {
      path: '/operational/affiliations',
      name: 'Affiliations',
      component: () => import('@/components/operational/affiliations/affiliations_dashboard'),
      meta: { permission: ['MENU_OP_AFFS'] },
    },
    {
      path: '/operational/organizations',
      name: 'Organizations',
      component: () => import('@/components/operational/organizations/organization_dashboard'),
      meta: { permission: ['MENU_OP_ORGS'] },
    },
    {
      path: '/operational/users/consents',
      name: 'Consents',
      component: () => import('@/components/operational/consents/consents_dashboard'),
      meta: { permission: ['MENU_OP_CONS'] },
    },
    {
      path: '/operational/users/trainings',
      name: 'Trainings',
      component: () => import('@/components/operational/trainings/trainings_dashboard'),
      meta: { permission: ['MENU_ADMIN_TRAININGS'] },
    },
    {
      path: '/operational/users/:id/:selected_card',
      name: 'User details',
      component: () => import('@/components/operational/user_details'),
      meta: { permission: ['MENU_OP_CONS'] },
    },

    {
      path: '/operational/users/:id/consents',
      name: 'User consents',
      component: () => import('@/components/operational/users/user_details/user_consents'),
      meta: { permission: ['MENU_OP_CONS'] },
    },
    {
      path: '/operational/proposals',
      name: 'Proposals',
      component: () => import('@/components/operational/proposals/proposals_dashboard'),
      meta: { permission: ['MENU_OP_PROPS'] },
    },
    {
      path: '/operational/calls',
      name: 'Call',
      component: () => import('@/components/call_admin_dashboard'),
      meta: { permission: ['MENU_OP_CALLS'] },
    },
    {
      path: '/operational/dangerous-actions',
      name: 'Dangerous Actions',
      component: () => import('@/components/operational/dangerousActions/DangerousActions'),
      meta: { permission: ['MENU_OP_DANGEROUS_ACTIONS'] },
    },
    {
      path: '/configuration/home',
      name: 'Home Dashboard configuration',
      component: () => import('@/components/home/configuration/HomeConfigurationDashboard'),
      meta: { permission: ['MENU_CONF_DASHB'] },
    },
    {
      path: '/regulations',
      name: 'regulations',
      component: () => import('@/components/clause/clause_view'),
    },
    {
      path: '/accessibility',
      name: 'Accessibility statement view',
      component: () => import('@/components/clause/AccessibilityStatementView'),
    },
    {
      path: '/password/reset',
      name: 'password-reset',
      component: () => import('@/components/before_login/reset_password_view'),
      meta: { whenLoggedAllowFrom: [] },
    },
    {
      path: '/password/:id/token/:token',
      name: 'password-new',
      component: () => import('@/components/before_login/new_password_view'),
      meta: { whenLoggedAllowFrom: [] },
    },
    {
      path: '/password/:type',
      name: 'password-change',
      component: () => import('@/components/before_login/change_password_view'),
    },
    {
      path: '/mail/resent',
      name: 'mail-resent',
      component: () => import('@/components/before_login/resent_email_view'),
      meta: { whenLoggedAllowFrom: [] },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/components/before_login/register'),
      meta: { whenLoggedAllowFrom: [] },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/components/before_login/login'),
      meta: { whenLoggedAllowFrom: [] },
    },
    { path: '*', redirect: '/home' },
  ],
});

function checkUserAffiliations() {
  return store.dispatch('login/load_self_affiliations')
    .then((resp) => !!resp.data.find(
      (el) => !['BLOCKED', 'DELETED', 'REJECTED'].includes(el.states.name),
    ));
}

router.beforeEach(async (to, from, next) => {
  const loggedIn = localStorage.getItem('user');
  const authRequired = (to.meta.permission !== undefined);

  if (loggedIn) {
    if (hasProperty(to.meta, 'whenLoggedAllowFrom')) {
      if (to.meta.whenLoggedAllowFrom.includes(from.name)) {
        return next();
      }
      return next(from.path);
    }

    if (authRequired) {
      const { permissions } = JSON.parse(loggedIn);
      if (to.meta.permission.some((perm) => permissions.includes(perm))) {
        const isAffiliations = await checkUserAffiliations();
        if (!isAffiliations) {
          if (to.path !== '/users/me' && from.path !== '/login') {
            Vue.notify({
              type: 'error',
              duration: 9000,
              title: 'To visit this dashboard you have to complete at least one active affiliation.',
            });
            return next('/users/me');
          }
        }
        return next();
      }

      Vue.notify({
        type: 'error',
        duration: 8000,
        title: 'You cannot visit this dashboard.',
        text: 'Please contact with User Office Manager.',
      });
      return next(false);
    }

    // when !authRequired
    return next();
  }

  if (authRequired) return next('/login');

  // when !authRequired
  return next();
});
