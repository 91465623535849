<template>
  <b-card bg-variant="light"
          class="ml-3 mr-3"
          header="Rapid access evaluation"
          header-bg-variant="dark"
          header-text-variant="white">

    <b-tabs v-model="selectedTab"
            card>
      <b-tab active
             class="pl-0 pr-0"
             title="Final evaluation">
        <rapid-access-evaluation-final/>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
  import RapidAccessEvaluationFinal from '@/components/evaluation/rapidAccess/technical/RapidAccessEvaluationFinal';

  export default {
    name: 'RapidAccessEvaluationDashboard',
    data() {
      return { selectedTab: 0 };
    },
    components: { RapidAccessEvaluationFinal },
  };
</script>

<style scoped>

</style>
