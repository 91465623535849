<template>
  <base-submit-info-modal ref="rapidAccessEvaluationFinalModal"
                          :confirm-function="saveEvaluation"
                          confirm-button-title="Save evaluation"
                          modal-title="Final rapid access evaluation">
    <template>
      <rapid-access-evaluation-final-form v-model="evaluation"
                                          :isEditable="isEvaluationEditable"/>
    </template>
    <template>
      <p class="p-form-label-with-margin-bold">Safety Manager comments</p>
      <b-form-textarea id="safety_comments"
                       v-model="proposal.samples[0].safety_evaluation.comments_review"
                       disabled
                       rows="2"/>
      <p class="p-form-label-with-margin-bold">Radiation Manager comments</p>
      <b-form-textarea id="radiation_comments"
                       v-model="proposal.samples[0].radiation_evaluation.comments_review"
                       class="mb-3"
                       disabled
                       rows="2"/>
    </template>
  </base-submit-info-modal>
</template>

<script>
  import BaseSubmitInfoModal from '@/components/baseComponents/baseModals/BaseSubmitInfoModal';
  import RapidAccessEvaluationFinalForm
    from '@/components/evaluation/rapidAccess/technical/RapidAccessEvaluationFinalForm';
  import { mapGetters } from 'vuex';
  import { setSafetyRadiationCommentsInProposal } from '@/helpers/rapidAccessSafetyRadiationEvaluation';

  export default {
    name: 'RapidAccessEvaluationFinalModal',
    components: {
      BaseSubmitInfoModal,
      RapidAccessEvaluationFinalForm,
    },
    props: {
      isEvaluationEditable: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return { evaluation: {} };
    },
    computed: {
      ...mapGetters('rapidAccessEvaluation', ['finalEvaluationSelectedProposalInhouseGrade']),
      proposal() {
        const proposal = this.$store.state.rapidAccessEvaluation.finalEvaluationSelectedProposal;
        return setSafetyRadiationCommentsInProposal(proposal);
      },
    },
    watch: {
      finalEvaluationSelectedProposalInhouseGrade: {
        handler() {
          this.evaluation = { ...this.finalEvaluationSelectedProposalInhouseGrade };
        },
      },
    },
    methods: {
      hide() {
        this.$refs.rapidAccessEvaluationFinalModal.hide();
      },
      async saveEvaluation() {
        if (this.isEvaluationEditable) {
          await this.$store.dispatch('rapidAccessEvaluation/saveSelectedProposalFinalEvaluation', this.evaluation);
          this.hide();
        } else {
          this.$notify({ type: 'error', title: 'Evaluation is not editable' });
        }
      },
      show() {
        this.$refs.rapidAccessEvaluationFinalModal.show();
      },
    },
  };
</script>

<style scoped>

</style>
