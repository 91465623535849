<template>
  <div>
    <b-table :current-page="currentPage"
             :empty-html="constantStrings.emptyDataHtml"
             :fields="tableFields"
             :items="proposals"
             :per-page="perPage"
             :responsive="true"
             :sort-by="'document_specification.document_id'"
             :sort-desc='true'
             class="table-responsive"
             head-variant="light"
             hover
             striped
             thead-class="text-center">

      <template v-slot:top-row="{fields}">
        <proposal-filter v-if="tableFieldsLoaded"
                         :id="id"
                         v-model="proposals"
                         :fields="fields"
                         :not_include_key="['actions', 'ordinalNumber']"
                         selected_call="None"
                         @component-created="emitSetupProposal(proposals)"/>
      </template>

      <template v-slot:cell(ordinalNumber)="row">
        <base-column-ordinal-number v-bind="{index: row.index, perPage: perPage, currentPage: currentPage}"/>
      </template>

      <template v-slot:cell(experiment_color.evaluated_color)="row">
        <strong :class="'text-' + getExperimentColor(row.item.experiment_color.evaluated_color)">
          {{ row.item.experiment_color.evaluated_color }}
        </strong>
      </template>

      <template v-slot:cell(actions)="row">
        <div @mousedown="setSelectedProposal(row.item)">
          <action-open-proposal-full-details :proposal="row.item"/>

          <action-download-pdf :proposal="row.item"/>

          <base-icon-button-comment :title="evaluationModalTitle(row.item)"
                                    @click="openEvaluationModal(row.item)"/>

          <base-icon-button-send v-if="isProposalGradeSubmittable(row.item)"
                                 :disabled="!isUserEditor"
                                 class="d-block ml-auto"
                                 title="Submit evaluation"
                                 @click="openConfirmationModal"/>
        </div>
      </template>
    </b-table>
    <table-footer :currentPage="currentPage"
                  :data_length="proposals.length"
                  :perPage="perPage"
                  :value="proposalsLoading"
                  @change_page="changePage"/>
  </div>
</template>

<script>
  import ActionDownloadPdf from '@/components/actions/ActionDownloadPdf';
  import ActionOpenProposalFullDetails from '@/components/actions/ActionOpenProposalFullDetails';
  import BaseColumnOrdinalNumber from '@/components/baseComponents/BaseColumnOrdinalNumber';
  import BaseIconButtonComment from '@/components/baseComponents/baseIcons/BaseIconButtonComment';
  import BaseIconButtonSend from '@/components/baseComponents/baseIcons/BaseIconButtonSend';
  import ProposalFilter from '@/components/proposal_filter';
  import TableFooter from '@/components/b_table_footer';
  import constantStrings from '@/json/constantStrings';
  import trafficLightsOptions from '@/json/trafficLightsAcceptanceMarkSafety';
  import { getHelpersProperties } from '@/helpers';

  export default {
    name: 'RapidAccessEvaluationFinalTable',
    components: {
      ActionOpenProposalFullDetails,
      ActionDownloadPdf,
      BaseColumnOrdinalNumber,
      BaseIconButtonComment,
      BaseIconButtonSend,
      ProposalFilter,
      TableFooter,
    },
    props: {
      proposalsLoading: {
        type: Boolean,
        default: false,
      },
      value: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        id: 1,
        currentPage: 1,
        perPage: 20,
        proposals: [],
        tableFields: [],
        tableFieldsLoaded: false,
        toggleRefreshTable: true,
        constantStrings,
        trafficLightsOptions,
      };
    },
    computed: {
      isUserEditor() {
        const permissions = this.$store.getters['login/getLoggedUserAttribute']('permissions') || [];
        return permissions.includes('EDIT_EVALUATION_RAPID_ACCESS_TECHNICAL');
      },
    },
    watch: {
      value: {
        handler() {
          this.proposals = this.value;
          this.emitSetupProposal(this.proposals);
        },
        immediate: true,
        deep: true,
      },
    },
    created() {
      this.loadFields();
    },
    methods: {
      changePage(val) {
        this.currentPage = val;
      },
      getExperimentColor(status) {
        let variant = 'secondary';
        this.trafficLightsOptions.forEach((option) => {
          if (option.value === status) {
            variant = option.disabledVariant;
          }
        });
        return variant;
      },
      isProposalEvaluationEditable(proposal) {
        return this.isProposalGradeEditable(proposal) && this.isUserEditor;
      },
      isProposalGradeEditable(proposal) {
        if (this.isProposalGradeEmpty(proposal)) {
          return true;
        }
        return this.isProposalGradeStateDraft(proposal);
      },
      isProposalGradeEmpty(proposal) {
        return this.isEmpty(proposal.inhouse_grade);
      },
      isProposalGradeStateDraft(proposal) {
        const gradeStateName = proposal.inhouse_grade.states.name;
        return gradeStateName === 'draft';
      },
      isProposalGradeSubmittable(proposal) {
        if (this.isProposalGradeEmpty(proposal)) {
          return false;
        }
        return this.isProposalGradeStateDraft(proposal);
      },
      evaluationModalTitle(proposal) {
        if (this.isProposalEvaluationEditable(proposal)) {
          return 'Edit evaluation';
        }
        return 'View evaluation';
      },
      async loadFields() {
        const helpers = await getHelpersProperties('rapidAccessEvaluation');
        this.tableFields = helpers.getSingleField('rapidAccessEvaluationFinalTableFields');
        this.tableFieldsLoaded = true;
      },
      openConfirmationModal() {
        this.$emit('open-submit-confirmation-modal');
      },
      openEvaluationModal(proposal) {
        const isEvaluationEditable = this.isProposalEvaluationEditable(proposal);
        this.$emit('open-rapid-access-evaluation-final-modal', isEvaluationEditable);
      },
      setSelectedProposal(proposal) {
        this.$store.commit('rapidAccessEvaluation/setFinalEvaluationSelectedProposal', proposal);
      },
    },
  };
</script>

<style scoped>

</style>
